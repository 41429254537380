<template>

  <div>
    <QuixAutocomplete
    :list="shops"
    @quix-selection-made="handleSel"
    :presetValue="'myval'"
    ></QuixAutocomplete>
  </div>
</template>

<script>
import QuixAutocomplete from '@/views/QuixAutocomplete'
import list from '@/search.json'

export default {
  name: "Demo",
  components:{
    QuixAutocomplete
  },
  data(){
    return {
      shops: list
    }
  },
  methods:{
    handleSel(sel){
      alert('item choosen from plugin')
      console.log(sel)
    }
  }
}
</script>

<style scoped>

</style>