<template>
  <div class="dropdown">

    <input autocomplete="off"
           placeholder="Type.."
           :disabled="inputValueDisabled"
           type="text"
           v-model="value"
           @input="searchValue()"
           @keydown="handleKeyDown"
    />

    <ul id="quix-suggestion-container" class="dropdown-menu" style="z-index:1; overflow-y: auto; max-height:200px "
        v-bind:style="{ display: display}">
      <li v-for="(item, index) in matchingList"
          :class="activeClass(index)" class="quix-suggestion-item"
          :key="item.id"
          @click="selectItem(index)"
          @keydown="handleKeyDown"
          @mousemove="hoverItem(index)">
        <a>{{ item.name }}</a>
      </li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "QuixAutocomplete",

  props: {
    list: {type: Array, required: true},
    inputValueDisabled: {type: Boolean, required: false, default: false},
    presetValue: {type: String, required: false, default: ''},
  },

  data: function () {
    return {
      item: {},
      matchingList: [],
      value: "",
      display: "none",
      focusItem: 0,
      selection: null
    }
  },
  watch: {
    matchingList: {
      handler: function (val, oldVal) {
        if(val.length){
          this.display = 'block'
        }
      },
      deep: true
    }
  },

  created(){
    if(this.presetValue){
      this.value = this.presetValue
    }
  },

  methods: {
    handleKeyDown(e) {

      if (this.matchingList.length == 0) return;

      var key = e.keyCode;

      // Key List
      const DOWN = 40;
      const UP = 38;
      const ENTER = 13;
      const ESC = 27;

      if (
          (key == UP && this.focusItem <= 0) ||
          (key == DOWN && this.focusItem >= this.matchingList.length - 1)
      ) {
        e.preventDefault();
        return;
      }

      var container = this.$el.querySelector("#quix-suggestion-container");

      switch (key) {
        case DOWN:
          e.preventDefault();
          this.focusItem++;
          break;
        case UP:
          e.preventDefault();
          this.focusItem--;
          break;
        case ENTER:
          e.preventDefault();
          this.display = "none";
          if (this.matchingList.length > 0)
            this.selectItem(this.focusItem);
          break;
        case ESC:
          break;
      }
      container.scrollTop = container.children[0].clientHeight * this.focusItem;

    },

    activeClass(i) {
      const focusClass = i === this.focusItem ? 'quix-active' : "";
      return `${focusClass}`;
    },

    hoverItem(index) {
      this.focusItem = index;
    },
    selectItem: function (index) {
      if(!this.matchingList || !this.matchingList[index]){
        return
      }
      this.selection = this.matchingList[index];
      this.value = this.selection.name
      this.$emit('quix-selection-made', this.selection);
      this.focusItem = -1;
      this.display = "none";
    },

    searchWord(val, data, searchByNode){
      //credits : https://github.com/irshadvali/search-in-array/blob/e0a540bc0f2a3a77cecdff20ded9fbf695ad7fd6/index.js
      let text;
      if (val) {
        text = val.toLowerCase();
        let filteredData = data.filter(item => {
          return item[searchByNode].toLowerCase().indexOf(text) > -1;
        });
        if (!Array.isArray(filteredData) && !filteredData.length) {
          return "result is empty";

        } else if (Array.isArray(filteredData)) {
          return filteredData;
        }
      } else {
        return data;
      }
    },

    searchValue () {
      this.focusItem = -1
      this.display = "none";
      this.selection = null;

      if (this.value.length == 0) {
        this.$emit('parentevent', this.selection);
        return;
      }
      if (this.value.length < 2) return;
      let matchingList = this.searchWord(this.value, this.list, 'name')
      this.matchingList = matchingList
    }
  }
}
</script>

<style scoped>
#quix-suggestion-container {
  border: 2px solid black;
}
.quix-suggestion-item{
  display: block;
}
.quix-active{
  background: #eee;
}
</style>